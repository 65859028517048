(function e(t,n,r){function s(o,u){if(!n[o]){if(!t[o]){var a=typeof require=="function"&&require;if(!u&&a)return a(o,!0);if(i)return i(o,!0);var f=new Error("Cannot find module '"+o+"'");throw f.code="MODULE_NOT_FOUND",f}var l=n[o]={exports:{}};t[o][0].call(l.exports,function(e){var n=t[o][1][e];return s(n?n:e)},l,l.exports,e,t,n,r)}return n[o].exports}var i=typeof require=="function"&&require;for(var o=0;o<r.length;o++)s(r[o]);return s})({1:[function(require,module,exports){
"use strict";

$(document).on("ready", function () {
    //Plugins
    //var urlSite="http://localhost:8000/";
    //var urlSite="http://mikes.test/";
    var urlSite = "https://app.mikes-charters.com.mx/";
    // TinyMCE
    tinymce.init({
        selector: 'textarea.tiny',
        menubar: false,
        removed_menuitems: 'undo, redo,',
        skin: "lightgray",
        statusbar: false
    });

    $('#addAgent').on('show.bs.modal', function (e) {
        var agencia_id = $("#agencies_id").val();
        $(e.currentTarget).find('select[name="agencies_id"]').val(agencia_id);
    });

    $("input.report-init").datepicker({
        dateFormat: "dd-mm-yy",
        maxDate: new Date($('#max_date_report').val()),
        minDate: new Date($('#min_date_report').val())
    });

    $("input.report-end").datepicker({
        dateFormat: "dd-mm-yy",
        maxDate: new Date($('#max_date_report').val()),
        minDate: new Date($('#min_date_report').val())
    });

    $('form[name="reportes_form"] input[type=reset]').on('click', function () {
        $('form[name="reportes_form"] input[type="text"]').datepicker('destroy');
        $('form[name="reportes_form"] input[name="from"]').val('');
        $('form[name="reportes_form"] input[name="to"]').val('');
        $('form[name="reportes_form"] input[name="search"]').val('');
        $('form[name="reportes_form"] select').val(0);
        $('form[name="reportes_form"]').submit();
    });

    if (typeof $('#section-reportes').val() != 'undefined') {
        chargeSelectAgents();
    }

    var month = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

    //Date Picker español
    $.datepicker.regional['es'] = {
        closeText: 'Cerrar',
        prevText: '<Ant',
        nextText: 'Sig>',
        currentText: 'Hoy',
        monthNames: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
        monthNamesShort: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'],
        dayNames: ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'],
        dayNamesShort: ['Dom', 'Lun', 'Mar', 'Mié', 'Juv', 'Vie', 'Sáb'],
        dayNamesMin: ['Do', 'Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sá'],
        weekHeader: 'Sm',
        dateFormat: 'dd-mm-yy',
        firstDay: 1,
        isRTL: false,
        showMonthAfterYear: false,
        yearSuffix: ''
    };
    $.datepicker.setDefaults($.datepicker.regional['es']);

    if (top.location.pathname === '/reportes') {
        $(window).load(function () {
            ajaxAgenciesServices();
        });
    }

    $('form[name="reportes_form"] input[name="from"], form[name="reportes_form"] input[name="to"]').change(ajaxAgenciesServices);

    function ajaxAgenciesServices() {
        var $from = $('form[name="reportes_form"] input[name="from"]').val();
        var $to = $('form[name="reportes_form"] input[name="to"]').val();

        $.ajax({
            url: $('form[name=reportes_form]').data('agencies'),
            type: 'get',
            data: {
                from: $from,
                to: $to
            },
            success: function success(data) {
                $('select[name="agencia"]').removeAttr('disabled');
                var agencies = JSON.parse(data);
                $('select[name="agencia"]').find('option').remove();
                $('select[name="agencia"]').append('<option value="" selected="selected"> [ Agencia ] </option>');
                $.each(agencies, function (i, value) {
                    $('select[name="agencia"]').append('<option value="' + value["id_agencie"] + '"> ' + value["name_agencie"] + ' </option>');
                });
            },

            error: function error(err) {
                console.log('Error agencia');
            }
        });

        $.ajax({
            url: $('form[name=reportes_form]').data('services'),
            type: 'get',
            data: {
                from: $from,
                to: $to
            },
            success: function success(data) {
                $('select[name="servicio"]').removeAttr('disabled');
                var agencies = JSON.parse(data);
                $('select[name="servicio"]').find('option').remove();
                $('select[name="servicio"]').append('<option value="" selected="selected"> [ Servicio ] </option>');
                $.each(agencies, function (i, value) {
                    $('select[name="servicio"]').append('<option value="' + value["id_service"] + '"> ' + value["name_service"] + ' </option>');
                });
            },

            error: function error(err) {
                console.log('Error service');
            }
        });

        $.ajax({
            url: $('form[name=reportes_form]').data('services'),
            type: 'get',
            data: {
                from: $from,
                to: $to
            },
            success: function success(data) {
                $('select[name="servicio"]').removeAttr('disabled');
                var agencies = JSON.parse(data);
                $('select[name="servicio"]').find('option').remove();
                $('select[name="servicio"]').append('<option value="" selected="selected"> [ Servicio ] </option>');
                $.each(agencies, function (i, value) {
                    $('select[name="servicio"]').append('<option value="' + value["id_service"] + '"> ' + value["name_service"] + ' </option>');
                });
            },

            error: function error(err) {
                console.log('Error service');
            }
        });
    }

    if ($('input[name="dates_available"]').val()) var availableDates = $('input[name="dates_available"]').val().split(',');

    function available(date) {
        var dmy = date.getDate() + "-" + (date.getMonth() + 1) + "-" + date.getFullYear();
        if ($.inArray(dmy, availableDates) != -1) {
            return [true, "", "Available"];
        } else {
            return [false, "", "unAvailable"];
        }
    }

    $("input.date.select").datepicker({
        dateFormat: "dd-mm-yy",
        minDate: 'today',
        beforeShowDay: available
    });
    $("input.date").datepicker({
        dateFormat: "dd-mm-yy",
        maxDate: '+4Y'
    });

    $('.clockpicker').clockpicker({
        placement: 'bottom',
        align: 'left',
        donetext: 'Guardar',
        autoclose: true,
        minutestep: 15
    });

    // twelvehour: true,

    var EventsData = [];

    // Modal de Eliminar artículos
    $('#sureDelete').on('show.bs.modal', function (event) {
        var button = $(event.relatedTarget); // Button that triggered the modal
        var $name = button.data('nombre');
        if (button.data('muelle-id')) {
            var $id = button.data('muelle-id');
            var ref = 'muelle';
        } else if (button.data('barco-id')) {
            var $id = button.data('barco-id');
            var ref = 'barcos';
        } else if (button.data('servicio-id')) {
            var $id = button.data('servicio-id');
            var ref = 'servicios';
        } else if (button.data('reservacion-id')) {
            var $id = button.data('reservacion-id');
            var ref = 'reservaciones';
        } else if (button.data('evento-id')) {
            var $id = button.data('evento-id');
            var ref = 'eventos';
        } else if (button.data('hotel-id')) {
            var $id = button.data('hotel-id');
            var ref = 'hoteles';
        } else if (button.data('agencia-id')) {
            var $id = button.data('agencia-id');
            var ref = 'agencias';
        } else if (button.data('agente-id')) {
            var $id = button.data('agente-id');
            var ref = 'agentes';
        } else {
            console.log('no data ID?');
        }
        var modal = $(this);
        modal.find('.modal-title').text('¿Eliminar ' + $name + ' ?');
        modal.find('.delete-button').attr('href', '/' + ref + '/' + $id + '/eliminar/');
    });
    // Mostrar u ocultar campos en Vista single de Muelles
    var $changeMuellesCharges = $('.changeMuellesCharges');

    $changeMuellesCharges.on('click', function (e) {

        e.preventDefault();

        if ($(this).hasClass('btn-default')) {
            $(this).closest('.row').find('.toggleHidden').toggleClass('hidden');
            $(this).removeClass('btn-default').addClass('btn-primary').toggleClass('btn-sm').text('Guardar');
        } else {
            $(this).closest('form').submit();
        }
    });
    // Control de formularios => Campos de Hora y de Moneda
    var $form = $('.form-horizontal');
    var $maxHours = $form.find('input[name=max-hours]');
    var $minHours = $form.find('input[name=min-hours]');
    var $moneyInput = $form.find('.input-group-addon:contains("$")~input');

    // Campos de Moneda
    function validateMoneyInput() {
        var $valueInput = $(this),
            value = parseFloat($valueInput.val().replace(',', '.')).toFixed(2);

        if (isNaN(value)) value = 0;

        $valueInput.val(value);
    }

    // Máximo debe ser mayor a mínimo
    function validateMinAndMax() {
        var max = Number($maxHours.val());
        var min = Number($minHours.val());
        if (max != 0 && max != null && max != '') {
            if ($(this).attr('name') == 'min-hours') min = $(this).val();
            if ($(this).attr('name') == 'max-hours') max = $(this).val();

            $form.find('.js-alert-box').remove();

            if (max <= min) {
                $(this).parent().addClass('has-error has-feedback').append('<span class="glyphicon glyphicon-remove form-control-feedback" aria-hidden="true"></span>');
                $form.find('button[type=submit]').addClass('disabled');
                $form.prepend('<div class="js-alert-box alert alert-danger"><ul><li>El <strong>Máximo de horas</strong> debe ser mayor al <strong>Mínimo de Horas</strong></li></ul></div>');
            } else if (max > min) {
                $form.find('button[type=submit]').removeClass('disabled');
                $maxHours.parent().removeClass('has-error has-feedback').find('.glyphicon').remove();
                $minHours.parent().removeClass('has-error has-feedback').find('.glyphicon').remove();
            }
        }
    }

    /*$maxHours.on('change', validateMinAndMax)
     $minHours.on('change', validateMinAndMax)
     $moneyInput.on('change', validateMoneyInput)*/

    // Formulario de Servicios, Muelles y Barcos dinámicamente
    /*var $ElegirMuelles = $('#ElegirMuelles'),
     $ElegirBarcos   = $('#ElegirBarcos'),
     $muelles        = $('input[name=muelles]'),
     $barcos         = $('input[name=barcos]'),
     $HorariosSalida = $('#HorariosSalida'),
     salidas         = {},
     elegidosMuelles = [],
     elegidosBarcos  = []*/

    // Para Tipo de Cobro
    /* $('select[name="charged"]').on('change', function() {
     if ($(this).val() == 2) {
     $('.min_pax').slideDown()
     } else {
     $('.min_pax').slideUp()
     }
     })*/

    // Primero validamos si el campo oculto 'muelles' ya tiene un valor
    /*if ($muelles.val()) {
     var $MuellesOpts = $('select[name=selectMuelles]')
      $.each($muelles.val().split(','), function(i,v) {
     elegidosMuelles.push(v)
     $ElegirMuelles.find('.btn-group').append('<div type="button" class="btn btn-default clearfix" data-muelle-id="'+ v +'"><span class="pull-left">' +
     $MuellesOpts.find('option[value='+v+']').text() + '</span> <a class="btn-xs close pull-right" aria-label="Close"><span class="badge" aria-hidden="true">&times;</span></a>' +
     '</div> ')
     $MuellesOpts.find('option[value='+v+']').addClass('hidden').hide()
     })
     }
      // Validamos si el campo oculto 'barcos' ya tiene un valor
     if ($barcos.val()) {
     var $BarcosOpts = $('select[name=selectBarcos]')
      $.each($barcos.val().split(','), function(i,v) {
     elegidosBarcos.push(v)
     $ElegirBarcos.find('.btn-group').append('<div type="button" class="btn btn-default clearfix" data-barco-id="'+ v +'"><span class="pull-left">' +
     $BarcosOpts.find('option[value='+v+']').text() + '</span> <a class="btn-xs close pull-right" aria-label="Close"><span class="badge" aria-hidden="true">&times;</span></a>' +
     '</div> ')
     $BarcosOpts.find('option[value='+v+']').addClass('hidden').hide()
     })
     }
      // Al hacer click en el botón de añadir muelle
     $ElegirMuelles.on('click', 'a', function(e) {
     e.preventDefault()
     var $opcionMuelle = $ElegirMuelles.find('select option:selected').text(),
     id = $ElegirMuelles.find('select option:selected').val(),
     EtiquetaMuelle = '<div type="button" class="btn btn-default clearfix" data-muelle-id="'+ id +'"><span class="pull-left">' +
     $opcionMuelle + '</span> <a class="btn-xs close pull-right" aria-label="Close"><span class="badge" aria-hidden="true">&times;</span></a>' +
     '</div> ',
     EtiquetaHorarios = '<fieldset class="well well-sm" data-muelle="'+ id +'"><input type="text" name="departure['+ id +']" hidden readonly>'+
     '<div class="input-group"><div class="input-group-btn">'+
     '<button class="btn btn-xs btn-link" type="button">'+ $opcionMuelle +'</button></div>'+
     '<input type="time" class="form-control" name="horarios-'+ id +'" value="05:00">'+
     '<span class="input-group-addon"><a href="#" class="glyphicon glyphicon-plus" data-muelle="'+ id +'"></a></span>'+
     '</div><div class="btn-group btn-group-sm well-sm" role="group"></div></fieldset>'
       if ( $.inArray(id, elegidosMuelles) == -1 && id != -1)
     {
     elegidosMuelles.push(id)
     $ElegirMuelles.find('.btn-group').append(EtiquetaMuelle)
     $ElegirMuelles.find('select').val('-1')
     $muelles.val(elegidosMuelles)
     $ElegirMuelles.find('option[value='+id+']').addClass('hidden').hide()
     $HorariosSalida.append(EtiquetaHorarios)
     $HorariosSalida.find('.horarios-salida-msj').remove()
     }
      if ($(this).hasClass('close')) {
     var button = $(this).parents('[type="button"]'),
     id = button.data('muelle-id').toString()
      elegidosMuelles.splice(elegidosMuelles.indexOf(id), 1)
     $muelles.val(elegidosMuelles)
     $ElegirMuelles.find('option[value='+id+']').removeClass('hidden').show()
     $HorariosSalida.find('fieldset[data-muelle="'+id+'"]').remove()
     button.remove()
     }
      })
      $ElegirBarcos.on('click', 'a', function(e) {
     e.preventDefault()
     var $opcionBarco = $ElegirBarcos.find('select option:selected').text(),
     id = $ElegirBarcos.find('select option:selected').val(),
     EtiquetaBarco = '<div type="button" class="btn btn-default clearfix" data-barco-id="'+ id +'"><span class="pull-left">' +
     $opcionBarco + '</span> <a class="btn-xs close pull-right" aria-label="Close"><span class="badge" aria-hidden="true">&times;</span></a>' +
     '</div> '
      // Valida si el elemento ya existe en el Array > inArray retorna el index o '-1' si no existe
     if ( $.inArray(id, elegidosBarcos) == -1 && id != -1)
     {
     elegidosBarcos.push(id)
     $ElegirBarcos.find('.btn-group').append(EtiquetaBarco)
     $ElegirBarcos.find('select').val('-1')
     $barcos.val(elegidosBarcos)
     $ElegirBarcos.find('option[value='+id+']').addClass('hidden').hide()
      }
      // Eliminamos el botón y el valor guardado
     if ($(this).hasClass('close')) {
     var button = $(this).parents('[type="button"]'),
     id = button.data('barco-id').toString()
      elegidosBarcos.splice(elegidosBarcos.indexOf(id), 1)
     $barcos.val(elegidosBarcos)
     $ElegirBarcos.find('option[value='+id+']').removeClass('hidden').show()
     button.remove()
     }
     })
      // Validamos si ya existen Horarios
     var horarios = $HorariosSalida.find("input[name^='departure']")
     $.each(horarios, function(i, e) {
     var v = $(e).val(),
     nameattr = $(e).attr('name'),
     t        = $(this).next().find('[type="button"]'),
     id       = nameattr.split('[')[1].replace(']', '')
      if (v) {
     var val = v.split(',')
     $.each(val, function (i, v) {
     // Valida si el muelle ya existe en el Objeto
     if (salidas.hasOwnProperty(id)) {
     // Valida si el horario ya está en el muelle
     if ( $.inArray(val, salidas[id]) == -1)
     {
     salidas[id].push(v)
     imprimirHorarios(id, v, t)
     }
     } else {
     salidas[id] = [v]
     imprimirHorarios(id, v, t)
     }
     })
     }
     })
      // Función para añadir y obtener los horario de salida por muelles
     $HorariosSalida.on('click', 'a', function(e) {
     e.preventDefault()
     var id = $(this).data('muelle'),
     v  = $HorariosSalida.find("input[name='horarios-"+id+"']").val(),
     t = $(this)
      // Valida si el muelle ya existe en el Objeto
     if (salidas.hasOwnProperty(id)) {
     // Valida si el horario ya está en el muelle
     if ( $.inArray(v, salidas[id]) == -1)
     {
     salidas[id].push(v)
     imprimirHorarios(id, v, t)
     }
     } else {
     salidas[id] = [v]
     imprimirHorarios(id, v, t)
     }
      // Eliminamos el botón y el valor guardado
     if ($(this).hasClass('close')) {
     var button = $(this).parents('[type="button"]'),
     id = button.data('muelle-id'),
     v = button.data('muelle-hora')
      salidas[id].splice(salidas[id].indexOf(v) , 1)
      imprimirHorarios(id, v, t)
     button.remove()
     }
     })
     function imprimirHorarios(id, v, t) {
     // t  = this (enlace clicado)
     // id = data-muelle id del button
     // v  = value
     t.parents('.input-group').next().append('<div type="button" class="btn btn-default clearfix" data-muelle-id="'+ id +'" data-muelle-hora="'+ v +'" ><span class="pull-left">'
     + v + '</span> <a class="btn-xs close pull-right" aria-label="Close"><span class="badge" aria-hidden="true">&times;</span></a>' +
     '</div> ')
     $HorariosSalida.find("input[name='departure["+id+"]']").val(salidas[id])
     }*/

    /*
     Elegir muelle y horario en 'Eventos'
     */
    /* var $MuellesSalidas = $('#MuellesSalidas'),
     $MuellesHorarios = $('#MuellesHorarios'),
     $piers = $('[name=piers]'),
     $MuellesSelect = $('[name=pier]'),
     elegidosMuellesHorarios = []
      // Primero validamos si el campo oculto 'piers' ya tiene un valor
     if ($piers.val()) {
     elegidosMuellesHorarios = JSON.parse($piers.val())
      $.each(elegidosMuellesHorarios, function(i ,v) {
     $MuellesHorarios.append('<div type="button" class="btn btn-default clearfix" data-muelle-id="'+ v.id +'"><span class="pull-left">' +
     $MuellesSelect.find('option[value='+v.id+']').text() + ' : ' + v.departure_hour +
     '</span> <a class="btn-xs close pull-right" aria-label="Close"><span class="badge" aria-hidden="true">&times;</span></a></div> ')
     $MuellesHorarios.addClass('well-sm')
     $MuellesSelect.find('option[value='+v.id+']').addClass('hidden').hide()
     })
     }*/

    // Añadir etiqueta
    /* $MuellesSalidas.on('click', 'a', function(e) {
     e.preventDefault()
     var  $Muelle = $MuellesSelect.find('option:selected').text(),
     id = $MuellesSelect.find('option:selected').val(),
     $Horario = $MuellesSalidas.find('[name="departure_hour"]').val(),
     Etiqueta = '<div type="button" class="btn btn-default clearfix" data-muelle-id="'+ id +'"><span class="pull-left">' +
     $Muelle + ' : ' + $Horario +'</span> <a class="btn-xs close pull-right" aria-label="Close"><span class="badge" aria-hidden="true">&times;</span></a>' +
     '</div> '
      // Valida si el elemento ya existe en el Array > inArray retorna el index o '-1' si no existe
     if ( $.inArray(id, elegidosMuellesHorarios) == -1 && id != -1 && $Horario > '0')
     {
     elegidosMuellesHorarios.push({
     'id': id,
     'departure_hour': $Horario
     })
      $MuellesHorarios.append(Etiqueta)
     $MuellesSelect.val('-1')
     $piers.val(JSON.stringify(elegidosMuellesHorarios))
     $MuellesSelect.find('option[value='+id+']').addClass('hidden').hide()
     }
      // Eliminamos el botón y el valor guardado
     if ($(this).hasClass('close')) {
     var button = $(this).parents('[type="button"]'),
     id = button.data('muelle-id').toString()
      elegidosMuellesHorarios = elegidosMuellesHorarios.filter(function (el) {
     return el.id != id
     })
     $piers.val(JSON.stringify(elegidosMuellesHorarios))
     $MuellesSelect.find('option[value='+id+']').removeClass('hidden').show()
     button.remove()
      console.log(elegidosMuellesHorarios)
     }
      })*/

    // Seleccionar Muelles y Barcos en > "Reservaciones"
    /* $('.selectablesElements').change( function() {
     console.log("change");
     var $form = $(this).closest('form');
      $form.append('<div id="loadingModal" class="modal fade" tabindex="-1" role="dialog">'+
     '<div class="modal-dialog modal-sm">'+
     '<div class="modal-content">'+
     '<div class="modal-header"><h3 class="modal-title">Cargando</h3></div>' +
     '<div class="modal-body">' +
     '<div class="progress progress-striped active"><div class="progress-bar" style="width: 100%"></div></div>' +
     '</div>' +
     '</div>'+
     '</div>'+
     '</div>')
      $('#loadingModal').modal()
      $form.attr('action', '/reservaciones/getdata')
     $form.attr('method', 'POST')
     $form.submit()
     })*/

    /*$( "#services-offers" ).change(function() {
      console.log($( "#services-offers" ).val());
       if($("#services-offers" ).val()==4){
        var $form =$("#formEvent");
      $form.append('<div id="loadingModal" class="modal fade" tabindex="-1" role="dialog">'+
     '<div class="modal-dialog modal-sm">'+
     '<div class="modal-content">'+
     '<div class="modal-header"><h3 class="modal-title">Cargando</h3></div>' +
     '<div class="modal-body">' +
     '<div class="progress progress-striped active"><div class="progress-bar" style="width: 100%"></div></div>' +
     '</div>' +
     '</div>'+
     '</div>'+
     '</div>')
      $('#loadingModal').modal()
      $form.attr('action', '/reservaciones/getdata')
     $form.attr('method', 'POST')
     $form.submit()
     $("#divPiers").hide();
      return false;
      }
      $("#divPiers").show();
      $("#piers-of-service opt").remove();
      $.ajax({
     type: "GET",
     url: "http://mikes.com/muelles_servicios",
     data: {
     id_service : $( "#services-offers" ).val()
     }
     })
      .done(function(jsonData){
      select ="<option value='-1' hidden >Elija un Muelle </option>";
     aux="";
       $.each(jsonData, function(i,data)
     {
     if($("#piers-of-service").val()==data.id){
     aux="selected";
     }
     select +='<option value="'+data.id+'"'+ aux+' >'+data.name+'</option>';
     aux="";
     });
       $("#piers-of-service").html(select);
      })
     });*/

    // Añadir Hoteles, Agencias y Agentes en Reservaciones
    $('form.ajax').on('submit', function (e) {
        e.preventDefault();

        var $form = $(this),
            formData = $(this).serialize(),
            // form data as string
        formAction = $(this).attr('action'),
            // form handler url
        formMethod = $(this).attr('method'),
            // GET, POST
        formSelect = $(this).data('campo'),
            formModal = $(this).data('modal');

        $.ajax({
            type: formMethod,
            url: formAction,
            data: formData,
            cache: false,

            success: function success(data) {
                $("#" + formModal).modal('hide');
                $("select[name=" + formSelect + "]").append('<option value="' + data.id + '" selected>' + data.name + '</option>');
            },

            error: function error(err) {
                var ul = $('<ul></ul>');
                $.each(err.responseJSON, function (i, v) {
                    var label = '<li>' + v[0] + '</li>';
                    ul.append(label);
                });
                $form.find('.alert-danger').removeClass('hidden').append(ul);
            }
        });
    });

    // Todos los Eventos
    var $FilterElements = $('#FilterElements'),
        $FilteredElements = $('#FilteredElements'),
        getEventsData = {
        filter: $FilterElements.find('input[name="filtro"]').val() || 'with-dates',
        uri: '/eventos/get-events-data/'
    };

    $FilterElements.find('select').on('change', function () {

        var arr = [],
            v = $FilterElements.find('input[name="filtro"]');

        if ($(this).val() != 'all') {

            $.each(v.val().split(','), function (i, v) {
                v ? arr.push(v) : '';
            });
            arr.push($(this).val());
        }

        v.val(arr);
        $FilterElements.submit();
    });

    // botones de Filtrado
    $FilteredElements.on('click', 'a', function (e) {
        e.preventDefault();
        var input = $FilterElements.find('input[name="filtro"]'),
            val = input.val().split(','),
            btn = $(this).parents('[type="button"]'),
            id = btn.data('servicio');

        if ($.inArray(id, val) == -1) {
            val.splice(val.indexOf(id.toString()), 1);
            input.val(val);
        }
        $FilterElements.submit();
    });

    // Formulario de Eventos
    var $FechasEvento = $('#FechasEvento'),
        $form = $FechasEvento.parents('form'),
        $Eventos = $('#Eventos'),
        fechas = [];
    // Editar horarios de eventos
    // Si ya existen fechas declaradas
    if ($('input[name="dates"]').val()) {
        var id = $form.find('[name="services_id"]').val(),
            meses = [];

        fechas = $('input[name="dates"]').val().split(',');

        // Obtener Meses (nums)
        meses = fechas.map(function (d) {
            return d.split('-')[1];
        });
        // Quitar duplicados
        meses = meses.filter(function (i, p) {
            return meses.indexOf(i) == p;
        });

        // Imprimir eventos editables
        // Button groups por mes
        $(meses).each(function (i, m) {
            $Eventos.append('<div class="btn-group btn-group-sm well well-sm" role="group" data-mes="' + m + '"><p><strong>' + moment(m, 'MM').format('MMMM') + '</strong></p></div>');
        });
        // Fechas del respectivo mes
        $(fechas).each(function (i, d) {
            var servicio = $form.find('select').find('option[value="' + id + '"]').text(),
                eventId = id + i;
            $Eventos.find('.btn-group[data-mes="' + d.split('-')[1] + '"]').append('<div type="button" class="btn btn-default clearfix" data-fecha="' + d + '" data-servicio-id="' + id + '" data-calendar-event-id="' + eventId + '"><span class="pull-left">' + d.split('-')[0] + '</span><a class="btn-xs close pull-right" aria-label="Close"><span class="badge" aria-hidden="true">&times;</span></a></div>');
        });
    }

    // Duración del evento
    var $hrs = $('[name="horas"]'),
        $mins = $('[name="minutos"]'),
        $duration = $('[name="duration"]'),
        $eventDuration = $('.eventDuration');

    function setEventDuration() {
        var hrs = parseInt($hrs.val()),
            mins = parseInt($mins.val());

        hrs = hrs < 10 ? '0' + hrs : hrs;
        mins = mins < 10 ? '0' + mins : mins;

        $duration.val(hrs + ':' + mins);
    }

    if ($duration.val()) {
        var duracion = $duration.val().split(':');

        $hrs.val(duracion[0]);
        $mins.val(duracion[1]);
    }

    $eventDuration.on('change', setEventDuration);

    // Agregar nuevos eventos
    $FechasEvento.on('click', 'button', function (e) {
        e.preventDefault();
        var t = $(this),
            form = $(this).parents('form'),
            id = form.find('[name="services_id"]').val(),
            date = form.find('.date').val(),
            hour = form.find('[name="departure_hour"]').val();

        form.find('.alert-danger').remove();
        console.log("id: " + id + " hour: " + hour);

        if (id > '0' && hour > '0') {

            if ($.inArray(date, fechas) == -1) {

                //Ordenar Fechas de Menor a Mayor
                // Función de parseo de fechas 'dd-mm-yyyy' a 'MM-DD-YY'
                var toDate = function toDate(dateStr) {
                    var parts = dateStr.split("-");
                    return new Date(parts[2], parts[1] - 1, parts[0]);
                };

                // Ordenar de menor a mayor


                fechas.push(date);fechas.sort(function (a, b) {
                    return toDate(a) > toDate(b) ? 1 : toDate(a) < toDate(b) ? -1 : 0;
                });

                var s = form.find('[name="services_id"]').find('option[value="' + id + '"]').text(),
                    d = date,
                    h = hour;
                renderEvent(id, s, d, h, form);
            }
        } else {
            var message;
            id < 0 ? message = 'Selecciona un Servicio primero' : hour < 0 ? message = 'Selecciona un Horario' : message = '';
            $(this).parents('form').prepend('<div class="js-alert-box alert alert-danger"><ul><li>' + message + '</li></ul></div>');
        }
    });

    $Eventos.on('click', '.close', function (e) {
        e.preventDefault();

        var button = $(this).closest('[type="button"]'),
            id = button.closest('.btn-group').data('servicio'),
            fecha = button.data('fecha'),
            eventId = button.data('calendar-event-id');

        fechas.splice(fechas.indexOf(fecha), 1);
        $form.find('input[name="dates"]').val(fechas);
        button.remove();
        removeEvent(eventId);
    });

    function removeEvent(id) {
        $('#calendar').fullCalendar('removeEvents', id);
    }

    function renderEvent(id, servicio, day, hour, form) {
        var input = form.find('input[name="dates"]'),
            newEvent = new Object(),
            meses = [],
            eventId;

        var barco = form.find('[name="ship"] option:selected').text();

        // Guardar en input para request
        input.val(fechas);
        // Quitamos los elementos visibles existentes en el DOM
        $Eventos.find('.btn-group').remove();
        // Obtener Meses (nums)
        meses = fechas.map(function (d) {
            return d.split('-')[1];
        });
        // Quitar duplicados
        meses = meses.filter(function (i, p) {
            return meses.indexOf(i) == p;
        });

        // Imprimir eventos editable
        // Button groups por mes
        $(meses).each(function (i, m) {
            $Eventos.append('<div class="btn-group btn-group-sm well well-sm" role="group" data-mes="' + m + '"><p><strong>' + moment(m, 'MM').format('MMMM') + '</strong></p></div>');
        });

        $(fechas).each(function (i, d) {
            var servicio = form.find('[name="services_id"]').find('option[value="' + id + '"]').text(),
                eventId = id + i;

            $Eventos.find('.btn-group[data-mes="' + d.split('-')[1] + '"]').append('<div type="button" class="btn btn-default clearfix" data-fecha="' + d + '" data-servicio-id="' + id + '" data-calendar-event-id="' + eventId + '"><span class="pull-left">' + d.split('-')[0] + '</span><a class="btn-xs close pull-right" aria-label="Close"><span class="badge" aria-hidden="true">&times;</span></a></div>');
        });

        // Imprimir evento en calendario
        newEvent.id = eventId;
        newEvent.title = servicio + "\n" + barco;
        newEvent.start = moment(day + ' ' + hour, 'DD-MM-YYYY HH:mm');
        newEvent.allDay = false;
        newEvent.editable = false;
        $('#calendar').fullCalendar('renderEvent', newEvent);
    }

    $('#calendar').fullCalendar({
        lang: 'es',
        allDay: false,
        contentHeight: 'auto',
        header: {
            left: 'prev,next today',
            center: 'title',
            right: 'agendaDay,basicWeek,month'
        },
        defaultView: 'basicWeek',
        buttonText: {
            agendaDay: 'Dia',
            basicWeek: 'Semana',
            month: 'Mes',
            today: 'Hoy'
        },
        dayClick: function dayClick(date, jsEvent, view) {
            var fecha = date.toString();
            var fecha = fecha.split(' ');
            var mes = parseInt(month.indexOf(fecha[1])) + 1;
            var mes = mes.toString();
            var mes = mes.length == 1 ? "0" + mes : mes;
            date = fecha[2] + "-" + mes + "-" + fecha[3];

            //window.location="http://mikes/eventos/nuevo?dateCalendar="+date;
            window.location = urlSite + "eventos/nuevo?dateCalendar=" + date;
        },
        loading: function loading(bool) {
            if (!bool) {
                $('.loading.modal').toggleClass('in').slideUp();
                setTimeout("$('.loading.modal').remove()", 400);
            }
        },
        events: {
            url: getEventsData.uri + getEventsData.filter,
            success: function success(data) {}
        },
        displayEventTime: false
    });

    var moverUrl = "/eventos/get-events-mover/" + $('input[name="filtro"]').val();

    $('#calendarMover').fullCalendar({
        lang: 'es',
        allDay: false,
        header: {
            left: 'prev,next today',
            center: 'title',
            right: 'agendaDay,agendaWeek,month'
        },
        buttonText: {
            agendaDay: 'Dia',
            agendaWeek: 'Semana',
            month: 'Mes',
            today: 'Hoy'
        },
        eventClick: function eventClick(event, jsEvent, view) {

            var event_arg = event.url.split('/')[2];
            //window.location="http://mikes/eventos/nuevo?dateCalendar="+date;
            //console.log(urlSite+"events-move-to-new/"+$('input[name="idReservacion"]').val()+"/"+date)
            console.log('evento');
            window.location = urlSite + "events-move-to/" + event_arg + "/" + $('input[name="idReservacion"]').val();
            return false;
        },
        dayClick: function dayClick(date, jsEvent, view) {
            var fecha = date.toString();
            var fecha = fecha.split(' ');
            var mes = parseInt(month.indexOf(fecha[1])) + 1;
            var mes = mes.toString();
            var mes = mes.length == 1 ? "0" + mes : mes;
            date = fecha[2] + "-" + mes + "-" + fecha[3];

            //window.location="http://mikes/eventos/nuevo?dateCalendar="+date;
            //console.log(urlSite+"events-move-to-new/"+$('input[name="idReservacion"]').val()+"/"+date)
            console.log('dia');
            window.location = urlSite + "events-move-to-new/" + $('input[name="idReservacion"]').val() + "/" + date;
            return false;
        },
        loading: function loading(bool) {
            if (!bool) {
                $('.loading.modal').toggleClass('in').slideUp();
                setTimeout("$('.loading.modal').remove()", 400);
            }
        },
        events: {
            url: moverUrl,
            data: { idReservacion: $('input[name="idReservacion"]').val() },
            success: function success(data) {
                console.log('hola');
                if (data.length == 0) {
                    $('#calendarMover').remove();
                    $('.panel-body').append('<div class="panel-body">' + '<p class="alert alert-info">No hay eventos para mostrar</p>' + '</div>');
                }
            }
        },
        displayEventTime: false
    });

    $('#calendarMoverEvento').fullCalendar({
        lang: 'es',
        allDay: false,
        header: {
            left: 'prev,next today',
            center: 'title',
            right: 'agendaDay,agendaWeek,month'
        },
        buttonText: {
            agendaDay: 'Dia',
            agendaWeek: 'Semana',
            month: 'Mes',
            today: 'Hoy'
        },
        dayClick: function dayClick(date, jsEvent, view) {
            var fecha = date.toString();
            var fecha = fecha.split(' ');
            var mes = parseInt(month.indexOf(fecha[1])) + 1;
            var mes = mes.toString();
            var mes = mes.length == 1 ? "0" + mes : mes;
            date = fecha[2] + "-" + mes + "-" + fecha[3];

            window.location = urlSite + "events-move-complete/" + $('#evento_id').val() + "/" + date;
        },
        loading: function loading(bool) {
            if (!bool) {
                $('.loading.modal').toggleClass('in').slideUp();
                setTimeout("$('.loading.modal').remove()", 400);
            }
        },
        events: {
            url: moverUrl,
            data: { idReservacion: $('input[name="idReservacion"]').val() },
            success: function success(data) {
                if (data.length == 0) {
                    $('#calendarMoverEvento').remove();
                    $('.panel-body').append('<div class="panel-body">' + '<p class="alert alert-info">No hay eventos para mostrar</p>' + '</div>');
                }
            }
        },
        displayEventTime: false
    });

    $('#calendarClonarEvento').fullCalendar({
        lang: 'es',
        allDay: false,
        header: {
            left: 'prev,next today',
            center: 'title',
            right: 'agendaDay,agendaWeek,month'
        },
        buttonText: {
            agendaDay: 'Dia',
            agendaWeek: 'Semana',
            month: 'Mes',
            today: 'Hoy'
        },
        dayClick: function dayClick(date, jsEvent, view) {
            var fecha = date.toString();
            var fecha = fecha.split(' ');
            var mes = parseInt(month.indexOf(fecha[1])) + 1;
            var mes = mes.toString();
            var mes = mes.length == 1 ? "0" + mes : mes;
            date = fecha[2] + "-" + mes + "-" + fecha[3];

            window.location = urlSite + "events-clonar-complete/" + $('#evento_id').val() + "/" + date;
        },
        loading: function loading(bool) {
            if (!bool) {
                $('.loading.modal').toggleClass('in').slideUp();
                setTimeout("$('.loading.modal').remove()", 400);
            }
        },
        events: {
            url: moverUrl,
            data: { idReservacion: $('input[name="idReservacion"]').val() },
            success: function success(data) {
                if (data.length == 0) {
                    $('#calendarClonarEvento').remove();
                    $('.panel-body').append('<div class="panel-body">' + '<p class="alert alert-info">No hay eventos para mostrar</p>' + '</div>');
                }
            }
        },
        displayEventTime: false
    });
    $('#calendarDepartures').fullCalendar({
        lang: 'es',
        allDaySlot: false,
        allDay: false,
        //googleCalendarApiKey: 'AIzaSyDpZTywcHvUCjpa-rfDF_CuMpu0UcfxGkc',        
        header: {
            left: 'prev,next today',
            center: 'title',
            right: 'agendaDay,basicWeek,month'
        },
        defaultView: 'basicWeek',
        buttonText: {
            agendaDay: 'Dia',
            basicWeek: 'Semana',
            month: 'Mes',
            today: 'Hoy'
        },
        displayEventTime: false,
        dayClick: function dayClick(date, jsEvent, view) {
            var fecha = date.toString();
            var fecha = fecha.split(' ');
            var mes = parseInt(month.indexOf(fecha[1])) + 1;
            var mes = mes.toString();
            var mes = mes.length == 1 ? "0" + mes : mes;
            date = fecha[2] + "-" + mes + "-" + fecha[3];

            window.location = urlSite + "eventos/nuevo?dateCalendar=" + date;
        },
        events: {
            url: getEventsData.uri + 'with-dates',
            success: function success(data) {}
        },
        loading: function loading(bool) {
            console.log("cargando calendarDepartures");
            if (bool) {
                $('body').append('<div id="loadingModal" class="modal fade" tabindex="-1" role="dialog">' + '<div class="modal-dialog modal-sm">' + '<div class="modal-content">' + '<div class="modal-header"><h4 class="modal-title">Cargando Salidas</h4></div>' + '<div class="modal-body">' + '<div class="progress progress-striped active"><div class="progress-bar" style="width: 100%"></div></div>' + '</div>' + '</div>' + '</div>' + '</div>');

                $('#loadingModal').modal('show');
            } else {
                $('#loadingModal').modal('hide');
            }
        }
    });
});

function chargeSelectAgents() {
    if ($('select[name="agencia"]').val() != "") {
        console.log('holaa');
        $.ajax({
            type: 'GET',
            url: $('form[name=reportes_form]').data('action'),
            data: {
                agencia: $('select[name="agencia"]').val()
            },
            cache: false,
            beforeSend: function beforeSend(data) {
                $('select[name="vendedor"]').attr('disabled', 'disabled');
            },
            success: function success(data) {
                $('select[name="vendedor"]').removeAttr('disabled');
                var agents = JSON.parse(data);
                $('select[name="vendedor"]').find('option').remove();
                $('select[name="vendedor"]').append('<option value="" selected="selected"> [ Vendedor ] </option>');
                var active_elem = "";
                $.each(agents, function (i, value) {
                    active_elem = value["id"] == $('#vendedor_active').val() ? ' selected="selected" ' : '';

                    $('select[name="vendedor"]').append('<option value="' + value["id"] + '"' + active_elem + '> ' + value["first_name"] + ' ' + value["last_name"] + ' </option>');
                });
            },

            error: function error(err) {
                console.log('Error');
            }
        });
    }
    $('select[name="agencia"]').on('change', function () {
        var $from = $('form[name="reportes_form"] input[name="from"]').val();
        var $to = $('form[name="reportes_form"] input[name="to"]').val();

        $.ajax({
            type: 'GET',
            url: $('form[name=reportes_form]').data('action'),
            data: {
                agencia: $(this).val(),
                from: $from,
                to: $to
            },
            cache: false,

            beforeSend: function beforeSend(data) {
                $('select[name="vendedor"]').attr('disabled', 'disabled');
            },
            success: function success(data) {
                $('select[name="vendedor"]').removeAttr('disabled');
                var agents = JSON.parse(data);
                $('select[name="vendedor"]').find('option').remove();
                $('select[name="vendedor"]').append('<option value="" selected="selected"> [ Vendedor ] </option>');
                $.each(agents, function (i, value) {
                    $('select[name="vendedor"]').append('<option value="' + value["id"] + '"> ' + value["first_name"] + ' ' + value["last_name"] + ' </option>');
                });
            },

            error: function error(err) {
                console.log('Error');
            }
        });
    });
}

},{}]},{},[1]);
